
import React from "react"
import ImageCoaching from './images/imageCoaching.js'
import ImageDevelopement from './images/imageDevelopement.js'
import ImageClimat from './images/imageClimat.js'
import ImageChange from './images/imageChange.js'
import ImageConflit from './images/imageConflit.js'
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby"

const FrontPageContent = () => (

    <div className="white-back">
        <div className="flex-container">
          <div className="half left-content tree-back">
              <ScrollAnimation
                  delay="10"
                  animateIn="fadeIn">
                  <h1 data-aos="fade-in" className="white">Nos engagements</h1>
                  <ul className="engagements">
                      <li><h6>Vous offrir des solutions simples et durables, adaptées à la réalité de votre organisation</h6></li>
                      <li><h6>Réaliser des interventions percutantes qui s'appuient sur des pratiques reconnues</h6></li>
                      <li><h6>Développer votre autonomie en partageant notre expertise et notre savoir-faire</h6></li>
                  </ul>
              </ScrollAnimation>
              <div className="left-square2"></div>
          </div>
          <div className="half right-content">
              <ScrollAnimation
                  delay="30"
                  animateIn="fadeIn">
                  <h2>Partenaire de votre succès</h2>
                  <p>GESTION MIEUX-ÊTRE vous offre une gamme de solutions au diapason de votre réalité afin d’accroître l’efficacité et la vitalité de votre entreprise. Nous vous soutenons afin de rendre votre environnement de travail plus humain.  Ensemble, nous explorons des avenues inspirantes qui contribuent à l’atteinte de vos objectifs. </p>
              </ScrollAnimation>
          </div>
        </div>
        <div className="main-wrapper pad tcenter">
            <ScrollAnimation
                delay="0"
                animateIn="fadeIn">
            <h1>Notre offre de service</h1>
            <p class="service-presentation">Partageant notre savoir-faire en développement organisationnel, nous vous accompagnons afin de relever vos défis de gestion et de transformation. Nous vous offrons des solutions simples et efficaces.  Les entreprises qui font appel à nos services notent des impacts positifs sur le niveau d’engagement de leurs collaborateurs.</p>

            </ScrollAnimation>
            <br /><br />
            <div className="flex-container2">
                <ScrollAnimation
                    delay="10"
                    animateIn="fadeIn"
                    className="fifth">
                <div>
                    <Link to="/coaching">

                        <ImageDevelopement />
                        <h6>Coaching</h6>
                    </Link>
                </div>
                </ScrollAnimation>
                <ScrollAnimation
                    delay="20"
                    animateIn="fadeIn"
                    className="fifth">
                <div>
                    <Link to="developpement-des-competences">
                        <ImageCoaching />
                        <h6>Développement des compétences </h6>
                    </Link>
                </div>
                </ScrollAnimation>
                <ScrollAnimation
                    delay="30"
                    animateIn="fadeIn"
                    className="fifth">
                <div>
                    <Link to="climat-et-sante">
                        <ImageClimat />
                        <h6>Climat de travail et santé organisationnelle </h6>
                    </Link>
                </div>
                </ScrollAnimation>
                <ScrollAnimation
                    delay="40"
                    animateIn="fadeIn"
                    className="fifth">
                <div>
                    <Link to="gestion-du-changement">
                        <ImageChange />
                        <h6>Gestion du changement </h6>
                    </Link>
                </div>
                </ScrollAnimation>
                <ScrollAnimation
                    delay="50"
                    animateIn="fadeIn"
                    className="fifth">
                <div>
                    <Link to="tensions-et-conflits">
                        <ImageConflit />
                        <h6>Dénouer les tensions et conflits </h6>
                    </Link>
                </div>
                </ScrollAnimation>
            </div>
            <br /><br /><br />
            <ScrollAnimation
                delay="10"
                animateIn="fadeIn">
            <Link className="button-link" to="nous-joindre">Nous joindre</Link>
            </ScrollAnimation>
            <br /><br />
        </div>
    </div>
)

export default FrontPageContent
