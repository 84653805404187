import React from "react"
import {Link} from 'gatsby'
import LogoAnimated from '../images/logo_gestion_animated.svg'
import ImageOptimiser from './images/imageOptimiser.js'
import ImageNatalie from './images/imageNatalie.js'
import ImageMobile from './images/imageMobile.js'
import ImageRight from '../images/image-right1.jpg'
import ScrollAnimation from 'react-animate-on-scroll';


class FrontPageHero extends React.Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    componentDidMount(){
        if(sessionStorage.getItem('loader') === 'true'){
            document.getElementById('hero-content-wrapper').classList.add('show-c-wrapper')
            document.getElementById('treeloader').classList.add('gone')
        } else {
            setTimeout(() => {
                sessionStorage.setItem('loader','true')
                document.getElementById('treeloader').classList.add('gone')
                document.getElementById('hero-content-wrapper').classList.add('show-c-wrapper')
            },3000)
        }
    }

    render(){
        return (
            <React.Fragment>
                <div id="treeloader" className="treeloader">
                  <img src={LogoAnimated} alt="Gestion Mieux-Etre" />
                </div>
                <div className="hero-content">
                    <div id="hero-content-wrapper" className="hero-content-wrapper">
                        <ImageOptimiser />
                        <p>Leader en développement, nous vous aidons afin d'énergiser les forces vives de votre organisation.</p>
                        <div className="owner-wrapper">
                            <ImageNatalie />
                            <div className="owner-details">
                                <b>Natalie Bertrand <small>(M.Sc. – CRHA)</small></b><br />
                                Fondatrice et associée principale
                            </div>
                        </div>
                    </div>
                    <div className="left-square"></div>
                </div>
                <ImageMobile />
                <div className="green-split">
                    <div className="main-wrapper flex-container">
                        <div className="intro-text tright">
                            <ScrollAnimation
                                delay="100"
                                animateIn="fadeIn">
                            <p>Notre approche de cocréation mise sur le développement d'une culture collaborative qui se distingue par des gestionnaires inspirants et des collaborateurs motivés et engagés.</p>
                            <Link to="/coaching" className="arrow-link">Consulter nos services</Link>
                            </ScrollAnimation>
                        </div>
                        <div className="intro-img">
                            <ScrollAnimation
                                delay="300"
                                animateIn="fadeIn">
                            <img className="rightImg" src={ImageRight} />
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="left-square2"></div>
                </div>
            </React.Fragment>

        )
    }



}

export default FrontPageHero
